import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";
import "../../assets/sass/articles.scss";

const PromoteWebsitePl = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Jak stworzyć stronę" language="pl"/>
            <article className="article">
                <div className="container">
                    <div className="article__header">
                        <HorizontalLine classname="white"/>
                        <h1 className="article__header--title">Kilka słów o historii - czyli od czego się zaczęło</h1>
                        <HorizontalLine classname="white"/>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            Wyobrażasz sobie życie bez internetu? Bez scrollowania fejsbuka w autobusie? Bez
                            sprawnej komunikacji ze znajomymi i szybkiego załatwiania spraw? Nie? Tak
                            właśnie myśleliśmy.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            Internet oferuje otwartość na wyciągnięcie ręki, funkcjonalność, oszczędza tak
                            potrzebny wszystkim czas i pozwala szybko kupić, to, czego nie można dostać
                            stacjonarnie. Korzyści jakie z niego płyną można by jeszcze długo wymieniać. Cała
                            historia internetu sięga zaledwie parę dekad i dziś na jego pomarańczowej stronie
                            przybliżymy Ci co nieco. Ale obiecujemy, że nie będzie takiej nudy jak w szkole na
                            lekcjach! Usiądź tylko wygodnie i posłuchaj.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            Jak się pewnie domyślasz internet na początku był wolny, bardzo wolny. I dostępny
                            wyłącznie dla elit. Dla wojska, środowisk naukowych i przedstawicieli zawodów
                            medycznych. No i dla rządu, jak moglibyśmy zapomnieć.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1990&nbsp;rok -&nbsp;</strong>nadszedł w końcu ten długo wyczekiwany dzień, kiedy to
                            internet został
                            przedstawiony nieco szerszej publiczności. Powstała również bazowa wersja języka
                            HTML, która umożliwiała budowę podstawowych stron internetowych. Mała ilość
                            grafiki, mała ilość kolorów, duża ilość tekstów. Tak właśnie wyglądał Internet.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1991&nbsp;rok -&nbsp;</strong>współtwórcą internetu był niejaki ​ Tom Berners - Lee
                            i to on zakodował
                            pierwszą stronę internetową (wow!).
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1993&nbsp;rok -&nbsp;</strong>cała sieć to ponad 10 milionów użytkowników oraz ponad
                            600 różnych
                            witryn.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1994&nbsp;rok -&nbsp;</strong>Web Design wstąpił na jakże szeroką drogę rozwoju.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1995&nbsp;rok -&nbsp;</strong>Pamiętacie tą słynną niebieską przeglądarkę? Właśnie
                            teraz Microsoft
                            wpuścił ją w obroty. Do dzisiaj to jest koszmar, który nawiedza większość
                            programistów i web developerów. Internet wreszcie nabrał kolorów, powoli zaczyna
                            tętnić życiem, pojawiają się nawet pierwsze gify.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1996&nbsp;rok -&nbsp;</strong>znany większości Flash i Javascript uatrakcyjniają
                            internet animacjami.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>Nadal&nbsp;1996 -&nbsp;</strong>sieć się wyplata. Osiągnęła już 74 miliony aktywnych
                            użytkowników
                            oraz ponad 650 tysięcy stron internetowych. Do już obecnego języka HTML dołącza
                            CSS.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>1998&nbsp;rok -&nbsp;</strong>280 milionów użytkowników oraz aż 2,2 miliona witryn.
                            Oficjalnie został
                            wprowadzony PHP3, który nie blokuje dróg a otwiera drogi do tworzenia bardziej dynamicznych
                            niż zwykle stron internetowych. Pojawia się również CSS3, który
                            dodaje do stron internetowych więcej funkcjonalności.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>2003&nbsp;rok -&nbsp;</strong>to przełomowy rok, w którym powstają pierwsze media
                            społecznościowe,
                            blogi oraz internetowe encyklopedie. To również era WEB 2.0, czyli sieci
                            umożliwiającej interakcje ze stronami internetowymi. Sieć ma ponad 282 milionów
                            użytkowników, oraz ponad 38 milionów rozmaitych stron.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>2008&nbsp;rok -&nbsp;</strong>wchodzi <strong>Mobile Web Design,</strong> czyli
                            dostępność
                            internetu na
                            urządzeniach mobilnych typu telefony czy tablety. Rozpoczyna się również
                            projektowanie stron pod kątem użytkowników korzystających z tychże urządzeń.
                            Zwraca się uwagę na szybki i łatwy dostęp do informacji.
                        </p>
                    </div>
                    <div className="article__block">
                        <p className="article__text">
                            <strong>2010&nbsp;rok -&nbsp;</strong>no i stało się! 2 biliony użytkowników oraz 240
                            milionów witryn
                            internetowych. Powstaje też HTLM5 - nastawiony na same sukcesy w wirtualnym
                            świecie.
                        </p>
                    </div>
                    <blockquote>
                        <p>
                            W Internecie to rzeczywistość zależy od Ciebie. Zdecydowanie warto się przyczynić,
                            by ten działał zawsze na naszą korzyść i stale się rozwijał. Ale czasem fajnie jest
                            podzielić się jedzeniem offline :)
                        </p>
                    </blockquote>
                </div>
            </article>
        </div>
    );
};
export default PromoteWebsitePl;